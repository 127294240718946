
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
import { Detail } from '@/types/parkStrategy'

@Component({
  name: 'HealthLifeDetail',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  private info: Detail | {} = {}

  get strategyId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getDetail()
  }

  // 获取详情
  getDetail () {
    this.$axios.get(this.$apis.parkStrategy.selectParkStrategyByStrategyId, {
      strategyId: this.strategyId
    }).then(res => {
      this.info = res || {}
    })
  }
}
